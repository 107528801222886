body {
  font-family: "Alegreya", serif;
  text-transform: lowercase;
  letter-spacing: 1px;
  position: relative;
}

::selection {
  color: white;
  background-color: #e8368f;
  /* f7ece9 */
}
.fade {
  background-color: rgba(0, 0, 0, 0.5);
}

/* h5 {
  text-transform: lowercase;
} */

i,
a {
  color: black;
}

h1 {
  /* font-family: Galada; */
  font-family: new-spirit, serif;
  font-weight: 700;
  /* font-style: normal; */
}

i:hover,
a:hover {
  color: #b990ff;
  text-decoration: none;
  transition: filter 0.5s ease;
}

.scroll-text {
  font-size: 18px;
}

.fa-arrow-circle-down {
  color: #b990ff;
}
.fa-arrow-circle-down:hover {
  color: #dac4ff;
}

.fa-arrow-circle-down::after {
  color: #dac4ff;
}

/* .navbar-toggler { */
/* background-color: whitesmoke; */
/* } */

.navbar-toggler:hover {
  background-color: #dac4ff;
}

#jumbotron-text {
  font-family: new-spirit, serif;
  text-align: center;
  color: black;
  letter-spacing: 1px;
  font-size: 50px;
}

#main-text {
  text-align: center;
  /* font-family: "Futura"; */
  letter-spacing: 0.1em;
  color: black;
}

.about-me,
.my-work,
.resume {
  font-family: new-spirit, serif;
  letter-spacing: 1px;
  background-color: #f5f7fa;
  color: black;
  text-align: center;
  padding-bottom: 80px;
  padding-top: 80px;
}

.horizontal-line {
  border-top: 3px solid #e8368f;
  width: 20%;
  margin: 0 auto;
  padding-bottom: 30px;
}

.active {
  font-weight: bold;
  color: #ff8cba;
}

.bio,
.skills-list,
.resume {
  font-family: "Alegreya", serif;
  letter-spacing: 1px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.project-list {
  padding-bottom: 30px;
  font-family: "Alegreya", serif;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.card {
  border: 0;
  background-image: cover;
}

.img-port {
  border-radius: 5%;
  filter: grayscale(100%);
  cursor: pointer;
}

.img-port:hover {
  transition: filter 0.5s ease;
  filter: grayscale(0%);
}

.skills {
  font-family: new-spirit, serif;
  letter-spacing: 1px;
  background-color: #f2ebfe;
  /* background-color: #eeeae4; */
  text-align: center;
  padding-bottom: 100px;
  padding-top: 80px;
}

#bio::before,
#skills::before,
#mywork::before,
#contact::before {
  display: block;
  content: " ";
  margin-top: -105px;
  height: 105px;
  visibility: hidden;
}

.fa-arrow-down,
.fa-download,
.fa.fa-envelope {
  color: #b990ff;
}

.btn {
  /* background-color: white; */
  letter-spacing: 1px;
  text-transform: lowercase;
  color: #b990ff;
  border-radius: 5px;
}

.btn:hover {
  background-color: #dac4ff;
  text-transform: lowercase;
  letter-spacing: 1px;
  color: white;
  transition: filter 0.2s ease;
}

.project-underline {
  position: absolute;
  top: 10%;
  left: 40%;
  border-top: 3px solid #e8368f;
  /* #f3cfd0; */
  width: 20%;
}

.mod-head {
  text-align: center;
  font-family: new-spirit, serif;
  margin-top: 20px;
  font-size: 25px;
}

.mod-btn {
  color: #b990ff;
}

.mod-btn:hover {
  color: #b990ff;
}

.modal-content {
  /* background-color: whitesmoke; */
  border-radius: 5%;
}

.modal-footer {
  justify-content: center;
  border-top: 0 none;
  margin-bottom: 20px;
}

.mod-pic {
  height: 150px;
  width: 300px;
}

.icon-html5,
.icon-javascript-alt,
.icon-mobile-device,
.icon-database {
  color: #ff8cba;
}

.sticky-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffe3ec;
  color: #bc0a6f;
  /* border-top: 8px solid #f3cfd0; */
  height: 50px;
}

@media (min-width: 768px) {
  .bio {
    width: 750px;
  }

  .img-port {
    margin: 10px;
  }
}
@media (max-width: 768px) {
  .project-list {
    width: auto;
  }
  #icon-row {
    display: grid;
  }

  #jumbotron-text {
    font-size: 45px;
  }

  #main-text {
    font-size: 20px;
  }

  .img-port {
    margin-bottom: 10px;
  }
}

@media (max-width: 576px) {
  .project-list {
    width: auto;
  }

  #icon-row {
    display: grid;
  }
  #jumbotron-text {
    font-size: 45px;
  }
  #main-text {
    font-size: 20px;
  }
}
